import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "../../components/common/seo"
import Layout from "../../components/common/layout"
import Modal from "react-modal"
import Hero from "../../components/members-zone/hero.js"

import DownloadImg from "../../../static/assets/image/Data-Suite-Download-button-120x115.jpg"
import setupImg1 from "../../../static/assets/image/setupImg1.png"
import setupImg2 from "../../../static/assets/image/setupImg2.png"
import setupImg3 from "../../../static/assets/image/setupImg3.png"
import setupImg4 from "../../../static/assets/image/setupImg4.png"
import setupImg5 from "../../../static/assets/image/setupImg5.png"
import setupImg6 from "../../../static/assets/image/setupImg6.png"
import setupImg7 from "../../../static/assets/image/setupImg7.png"
// import setupImg8 from "../static/assets/image/setupImg8.png"
import updateImg1 from "../../../static/assets/image/setupImg1.png"
import updateImg2 from "../../../static/assets/image/setupImg2.png"
import updateImg3 from "../../../static/assets/image/setupImg3.png"
import updateImg4 from "../../../static/assets/image/setupImg4.png"
import updateImg5 from "../../../static/assets/image/setupImg5.png"

const MembersZone = ({ data }) => {

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
    
 const [modalIsOpenTwo, setIsOpenTwo] = useState(false)
  function openModalTwo() {
    setIsOpenTwo(true)
  }
  function closeModalTwo() {
    setIsOpenTwo(false)
  }
  
  const [modalIsOpenThree, setIsOpenThree] = useState(false)
  function openModalThree() {
    setIsOpenThree(true)
  }
  function closeModalThree() {
    setIsOpenThree(false)
  }
    
const [modalIsOpenFour, setIsOpenFour] = useState(false)
  function openModalFour() {
    setIsOpenFour(true)
  }
  function closeModalFour() {
    setIsOpenFour(false)
  }

const [modalIsOpenFive, setIsOpenFive] = useState(false)
  function openModalFive() {
    setIsOpenFive(true)
  }
  function closeModalFive() {
    setIsOpenFive(false)
  }
    
const [modalIsOpenSix, setIsOpenSix] = useState(false)
  function openModalSix() {
    setIsOpenSix(true)
  }
  function closeModalSix() {
    setIsOpenSix(false)
  }
    
const [modalIsOpenSeven, setIsOpenSeven] = useState(false)
  function openModalSeven() {
    setIsOpenSeven(true)
  }
  function closeModalSeven() {
    setIsOpenSeven(false)
  }
  
const [modalIsOpenEight, setIsOpenEight] = useState(false)
  function openModalEight() {
    setIsOpenEight(true)
  }
  function closeModalEight() {
    setIsOpenEight(false)
  }    

const [modalIsOpenNine, setIsOpenNine] = useState(false)
  function openModalNine() {
    setIsOpenNine(true)
  }
  function closeModalNine() {
    setIsOpenNine(false)
  }  
  
  const [modalIsOpenTen, setIsOpenTen] = useState(false)
  function openModalTen() {
    setIsOpenTen(true)
  }
  function closeModalTen() {
    setIsOpenTen(false)
  }  
  
  const [modalIsOpenEleven, setIsOpenEleven] = useState(false)
  function openModalEleven() {
    setIsOpenEleven(true)
  }
  function closeModalEleven() {
    setIsOpenEleven(false)
  }  
  
  const [modalIsOpenTwelve, setIsOpenTwelve] = useState(false)
  function openModalTwelve() {
    setIsOpenTwelve(true)
  }
  function closeModalTwelve() {
    setIsOpenTwelve(false)
  }   
  
 const [modalIsOpenThirteen, setIsOpenThirteen] = useState(false)
  function openModalThirteen() {
    setIsOpenThirteen(true)
  }
  function closeModalThirteen() {
    setIsOpenThirteen(false)
  } 
  
const [modalIsOpenFourteen, setIsOpenFourteen] = useState(false)
  function openModalFourteen() {
    setIsOpenFourteen(true)
  }
  function closeModalFourteen() {
    setIsOpenFourteen(false)
} 

const [modalIsOpenFifteen, setIsOpenFifteen] = useState(false)
  function openModalFifteen() {
    setIsOpenFifteen(true)
  }
  function closeModalFifteen() {
    setIsOpenFifteen(false)
    } 
    
const [modalIsOpenSixteen, setIsOpenSixteen] = useState(false)
  function openModalSixteen() {
    setIsOpenSixteen(true)
  }
  function closeModalSixteen() {
    setIsOpenSixteen(false)
} 

const [modalIsOpenSeventeen, setIsOpenSeventeen] = useState(false)
  function openModalSeventeen() {
    setIsOpenSeventeen(true)
  }
  function closeModalSeventeen() {
    setIsOpenSeventeen(false)
} 

    
  Modal.setAppElement("#___gatsby")

    return (
        <div className="membersZone">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                <div className="membersZoneWrapper indMember">
                            <h2>Print Tutorials</h2>   
                            <h3>1. Choosing Inventory</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4LsiurWZ8uFm84CQf5m6fr/6a90e0da925254e2e1d558f2faed7823/um1_1.pdf">1.1 Finding which OOH Companies have a particular product within one or more markets</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7zaqNhMfvE2oV2dbNXxpNU/cc3b1d8098e4a6a296a0ae8b039410db/um1_2.pdf">1.2 Determining inventory to use in a plan</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2oWmPA7QlbPRAep0NLtdqv/67ffffc379f84388df4822fee9b62845/um1_3.pdf">1.3 Adding inventory to a new plan</a></li>
                            </ul>
                            <h3>2. Defining Plans</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5BJuRLTzkTZZNoFUWBUwfr/107c9f9c029e5966f40f7c6809f9f8d6/um2_1.pdf">2.1 Assigning the same single value to the number of weeks column for all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3DMwhCKxYaRKh4r1vnsrXf/a85e3b690408c4911eef16db43fa8fe8/um2_2.pdf">2.2 Assigning the same costing to all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/6jHPcUbJdTYWrr8EyQXjXb/47d2844c63090c86f3d0efc65ac1eb1b/um2_3.pdf">2.3 Assigning the same objective to all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1G9QtOfFhoiPKsKho5yV4t/92d3a217c8a6bdf3a225415ddd752a7f/um2_4.pdf">2.4 Defining a plan with the same campaign lengths for all products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/SAwFRTnKbPwJmcaqpFnds/e9e69b48174ea10f01bd45877e9abb03/um2_5.pdf">2.5 Defining a plan with different campaign lengths for some products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5EXyED4ITCM3umQZ8UqAJI/fe014e208540a15cac2dcc119c281989/um2_6.pdf">2.6 Defining a plan to compare different objective levels for each product</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2IoEi0YOLqBQHI2raJkV4T/3a65c8b83c62b91123f91bcf96ac496a/um2_7.pdf">2.7 Applying costs to a plan with the same campaign lengths for all products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2dsp9gJ9j8AJVfWQu2O2H4/e94f0caf97ee8125336ffbd0833d0154/um2_8.pdf">2.8 Overriding a circulation</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7szCC2r8PkwkHJfIPrSWkQ/053fa5e8436f51e7233b680e48b612bb/um2_9.pdf">2.9 Defining multiple plans to compare products</a></li>
                            </ul>   
                            <h3>3. Flighting</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3QsYTc0uouotbzsJ7RlcgQ/97137088e68f06f9ea17312eaa14c92d/um3_1.pdf">3.1 Creating a Flighting schedule for all selected inventory</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1IJiMjLLA31Y2AQgGmdtBs/8c4d12981863855714dc5511a5c3607e/um3_2.pdf">3.2 Creating different Flighting schedules that vary by product</a></li>
                            </ul>
                            <h3>4. Targets</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2SF6c6xYpLob1teL8jUI11/860e5fc6478d21b0f80b9c352af38a94/um4_1.pdf">4.1 Creating a new combined target</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3yeyWriGYn55qwMWOlp54o/62dc748aa52de8b305f8c66c023fa56e/um4_2.pdf">4.2 Adding a single target</a></li>
                            </ul>
                            <h3>5. Report Appearance</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/sJskb0nmp2vqSu5C9ALcT/a9732b254ccab71e10add4ce80dee5a4/um5_1.pdf">5.1 Enter or change the report title and footer</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5XVVG3eHDzh1AFm9YFzXjD/f3e7bf83d9b0c020ead59be4f20037bd/um5_2.pdf">5.2 Changing preferences</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4T8tnRdBE6I4IpcA2wHCGS/667ab62fa4400e97e28cf4e8b7394ead/um5_3.pdf">5.3 Adding a logo to reports</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2l8xCKni2t4SIC2hsGzvKb/3529f5caf7e6194c327bc36dc06e41a1/um5_4.pdf">5.4 Altering the columns of a report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1WeVwAcN2F7lTlwuX7dkVW/f41f08e168429f9990da899d86816441/um5_5.pdf">5.5 Changing the report group order for a single-level or mutli-level report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4mRObMKzv5RCHfy3bC8Az3/08a9142c606b458555504387753cdff3/um5_6.pdf">5.6 Changing the order of markets in a report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5S8txUFBr7STOtseeeUhQP/dcd4a0f1b0119048438689a3cdfeb404/um5_7.pdf">5.7 Removing mixes from a report</a></li>
                            </ul>
                            <h3>6. Managing Files</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/58Pjncd5t85Be0u0Dc8sLV/4ada7304f3255b8348013ad83a34ad03/um6_1.pdf">6.1 Saving data selections</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4W431f6LEyJL2MvTyRrHUA/011f64136952177fe75c7cf65c023da3/um6_2.pdf">6.2 Opening files</a></li>
                            </ul>
                            <h3>7. Viewing and Exporting Reports</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7G14cTtYfzgJEC9lOYWLhk/d7a958f9f43827dc970d7b7db80e125c/um7_1.pdf">7.1 Completing the report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3FqLvgDMRSBgN1IqbWvQKs/ddcb904206722b4f632bf98c2770ec2c/um7_2.pdf">7.2 Recalculating results</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/ru9WDVCTY2ibaso6SWWtg/9befeac5c68a4e0191de924504bad0af/um7_3.pdf">7.3 Saving another copy of the report under a different filename in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1nVtBGmFGFWl0LueV7iy1V/3e65355fda1c423f6724b2728d59d8cf/um7_4.pdf">7.4 Saving another copy of the report under a different filename in Excel 2013</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/58blpQ176UJThxRZ4tcBgg/4644e65cd10b26f239c0fca583d7f67d/um7_5.pdf">7.5 Saving a copy of the report to PDF format in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4WSZEwm7z3OcQT5NMqteVe/2998d3d712af05b0f5543b1625cde50a/um7_6.pdf">7.6 Saving a copy of the report to PDF format in Excel 2013</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1xu2UdUNhyTUQ2rjlYXkyY/4cf528de36b1476b78447eb324548482/um7_7.pdf">7.7 Sending a PDF copy of the report via email in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2Oko70qTEGSWcG7QIkY0Kx/ca0edcbf39e9c087c15439877f13d370/um7_8.pdf">7.8 Sending a PDF copy of the report via email in Excel 2013</a></li>
                            </ul>
                            <h3>8. Graphing</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/zES9upqsJfOByXtJXy1GM/4f6692aeef2cfe6ddc23b5b88603bf78/um8_1.pdf">8.1 Comparing Reach Build</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3XOfnCTzns805Cn3AJQlWf/c4b5250f0f505d82b3c9a01322c045a2/um8_2.pdf">8.2 Graphing Effective Reach</a></li>
                            </ul>
                </div>
            </Layout>
        </div>
    )
}

export default MembersZone